<template>
  <div class="container oci-check-in-out-body">    
    <DCNumberView/>
    <p></p>
    <b-card class="oci-check-in-out-card">  
        <b-row class="pb-0 justify-content-center">
            <b>Confirm delivery</b>
        </b-row>

        <div v-for="(item, index) in bookingLines.filter(item => !item.is_collection)" :key="index">
        <b-row class="align-items-center">
          <b-col cols="12">
            <template v-if="item.quantity > 0">
              {{ item.quantity }}X {{ item.type.name }}
            </template>
            <template v-else>
              {{ item.type.name }} - {{ item.notes }}
            </template>          
          </b-col>
        </b-row>
        </div> 
        <b-row class="align-items-center oci-check-in-button-container">
            <b-button
                type="submit"
                @click="GoToNextPage"
                class="w-100"
                :disabled="isInputDisabled"
            >
                Confirm
            </b-button>
        </b-row>
        <b-row class="pb-1">
          <b-col class="col-5 oci-projects-button-container">
            <b-button
              type="submit"
              @click="GoToPreviousPage"
              class="w-100"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />              
              Back
            </b-button>
          </b-col>
          <b-col class="col-7 oci-check-in-button-container">
            <b-button
              type="submit"
              @click="GoToNextPage"
              class="w-100"
            >
              Next
              <feather-icon
                icon="ChevronRightIcon"
                size="12"
              />              
            </b-button>
          </b-col>
        </b-row> 

    </b-card>

  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import { BImg, BCard, BRow, BCol, BForm, BFormGroup, BFormSelect, BInputGroupAppend, BFormInput, BButton, BInputGroup, } from 'bootstrap-vue'
import { bookingsMethods } from '@/components/ui/BookingsFunctions'
import DCNumberView from '@/views/DCNumberView.vue'

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    DCNumberView,
  },
  data() {
    return {
      reason: '',
      cancelReason: '',
      cancelOptions: [
        { text: 'No one present on site', value: 'no_on_site' },
        { text: 'Job rescheduled', value: 'job_resheduled' },
        { text: 'Other', value: 'other' },
      ],      
    };
  },  
  computed: {
    ...mapGetters({ currentBooking: 'app/getCurrentBookingData' }),
    ...mapGetters({ bookingLines: 'app/getBookingLines' }),
    isInputDisabled() {
      return (
        this.currentBooking?.status === 'completed' ||
        this.currentBooking?.status === 'rejected' ||
        this.currentBooking?.status === null
      );
    }    
  },
  methods: {
    GoToPreviousPage() {
      this.$router.go(-1)
    },
    GoToNextPage() {
      this.$router.push('/items_to_collect_dc')
    },
  },  
}
</script>
